import axios from 'axios'
import {
    getToken
} from '../util/auth'
import Vue from 'vue'
Vue.prototype.$axios = axios
// create an axios instance
const service = axios.create({
    baseURL: 'https://test.v2.kytapp.com/api',
    // baseURL: 'https://v2.kytapp.com/api',
    timeout: 5000 // request timeout
})
// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['token'] = getToken()
        // config.headers['token'] = '31d852f4-5375-4b62-9415-bf1f36f06c4b'
        config.headers['device'] = 'mobile'
        return config
    },
    error => {
        // do something with request error               
        console.log(error) // for debug 
        return Promise.reject(error)
    }
)
// response interceptor
service.interceptors.response.use( 
    response => {
        const res = response.data
        return res
    }
)

export default service